import React, { useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { page_access } from "../services/Api";
import Sidebar from "../Layout/Sidebar";

// Global cache to store access data for each pageid
const accessDataCache = {};

const AccessControlRoute = ({ element, permission, pageid }) => {
  const location = useLocation();
  const [accessData, setAccessData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Store previous route in cookies
    if (
      location.pathname !== "/" &&
      location.pathname !== "/logout" &&
      location.pathname !== "/forgotpass"
    ) {
      Cookies.set("previousRoute", location.pathname, { expires: 7 });
    }

    // If data exists in cache, use it
    if (accessDataCache[pageid]) {
      setAccessData(accessDataCache[pageid]);
      setLoading(false);
      return;
    }

    // Fetch access data only if not in cache
    (async () => {
      try {
        const response = await page_access(pageid);
        const accessObject =
          Array.isArray(response.data.Data) && response.data.Data.length > 0
            ? response.data.Data[0]
            : {};
        accessDataCache[pageid] = accessObject; // Cache the data
        setAccessData(accessObject);
      } catch (error) {
        console.error("Error fetching access data:", error);
      }
      setLoading(false);
    })();
  }, [location.pathname, pageid]);

  const hasPermission = accessData && accessData[permission] === true;

  if (loading) {
    return <></>;
  } else if (!hasPermission) {
    return (
      <div className="d-flex justify-content-between">
        <div>
          <Sidebar />
        </div>
        <div
          className="col-10 dash-pad login-container"
          style={{
            backgroundColor: "rgba(247, 244, 255, 1)",
            minHeight: "89vh",
          }}
        >
          <div
            className="banner col-12 align-items-center"
            style={{ backgroundColor: "white", minHeight: "98%" }}
          >
            <i
              className="fa-sharp-duotone fa-solid fa-hand"
              style={{
                fontSize: "4vw",
                color: "rgb(47, 111, 146)",
                marginBottom: "20px",
              }}
            ></i>
            <p
              className="side-title"
              style={{
                color: "rgb(47, 111, 146)",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              You do not have access to this route.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return element;
};

export default AccessControlRoute;
