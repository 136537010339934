// login
import axios from "axios";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { getApiBaseClient } from "../../env.js";
import { getApiBase } from "../../env.js";
import { decryption, encryption } from "../utils/utils.js";
import Cookies from "js-cookie";
import Logout from "../Pages/Logout.js";
import Maintenancemode from "../Pages/Maintenancemode.js";

const SECRET_KEY = "9lkeFOhxt4";

// const api_base = getApiBaseClient();
const API_URI1 = process.env.REACT_APP_API_BASE_CLIENT;

const api_base = getApiBase();
const API_URI2 = process.env.REACT_APP_API_BASE_ADMIN;
const dummytoken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoiMDQ4ZjMwN2ItMzhmMS00NzNkLTk2YzMtMjU0YmRkZWZhMGJjIiwiSXNDb21wYW55Ijp0cnVlLCJDb21wYW55VHlwZSI6MiwiQ29tcGFueU5hbWUiOiJNYWNQcm8iLCJHU1RJTiI6IjA5QUFHQ0Q1MjUwTjFaQSIsIlJlZ2lzdGVyZWRBZGRyZXNzIjoiU3VyYXQgR2hvZCBEaG9kIFJvYWQiLCJDdXN0b21lck5hbWUiOiJyaW5rdSIsIk1vYmlsZU51bSI6Ijk4NTg1NTY4NTIiLCJFbWFpbElEIjoiVTJGc2RHVmtYMStoUlNZdkUvdTN2ellTRzJIQ3BsRzFUaTZyVHBtMWV5UT0iLCJBZGRyZXNzIjoiU3VyYXQgR2hvZCBEaG9kIFJvYWQiLCJQaW5jb2RlIjoiMzk0MTA1IiwiQXJlYUlEIjoiMTYxYjJlZWMtZWU2MC00OTM5LTk2OTgtZWJhMWZhYjlkNTI5IiwiQ2l0eUlEIjoiYWNhYzNlMTAtNTNmNy0xMWVmLTg5MTYtODBjZTYyOWVmMTZiIiwiU3RhdGVJRCI6ImZjZmM0ZGUxLTUzZjYtMTFlZi04OTE2LTgwY2U2MjllZjE2YiIsIkFhZGhhckNhcmROdW1iZXIiOiI2NjIyMjM1MDkyODQiLCJQQU4iOiJCQUpQQzQzNTBNIiwiRHJpdmluZ0xpY2Vuc2VOdW1iZXIiOiJHMTI1NDc4NTIzIiwiSGFzUGFyZW50IjpmYWxzZSwiUGFyZW50SUQiOiI0MTE3ZGFkMC01YmQzLTQ0NGEtODRiNy04N2QxNWIyNjFhZDciLCJDcmVhdGVkQnkiOiJBc2hpc2gxMjMiLCJDcmVhdGVkQnlVc2VySUQiOiJmYzllMjc1Ni04NWY4LTRlOGEtYTMwYS04ZGViYThhNzBjMDgiLCJDcmVhdGVkT24iOiIyMDI0LTA4LTI4VDExOjI2OjIxLjAwMFoiLCJMYXN0TW9kaWZpZWRCeSI6IkFzaGlzaDEyMyIsIkxhc3RNb2RpZmllZEJ5VXNlcklEIjoiZmM5ZTI3NTYtODVmOC00ZThhLWEzMGEtOGRlYmE4YTcwYzA4IiwiTGFzdE1vZGlmaWVkT24iOiIyMDI0LTA4LTI4VDExOjI2OjIxLjAwMFoiLCJJUGFkZHJlc3MiOiI2NC4yMjcuMTc3LjExNCIsIlBsYXRmb3JtIjoiUG9zdG1hblJ1bnRpbWUvNy40MS4yIiwiQ2l0eSI6bnVsbCwiT1MiOiJMaW51eCBsaW51eCA2LjguMC0zNi1nZW5lcmljIiwiSXNBY3RpdmUiOnRydWUsIklzRGVsZXRlZCI6ZmFsc2UsImlhdCI6MTcyNTU5OTI2OSwiZXhwIjoxNzI4MTkxMjY5fQ.CUr7ZErlmILeRRZSffO4BTtoK1HOn76x_G222ezSF80";

export const loginUser = async (Username, Password) => {
  try {
    const response = await axios.post(
      `${API_URI2}/Auth`,
      {
        Username: Username,
        Password: encryption(Password),
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`, // Retrieve token from cookies
        },
      }
    );

    const token = response.data?.token;
    const accountStatus = response.data?.account_status;

    // Debugging: Check if token and account status are retrieved correctly
    console.log("Retrieved token:", token);
    console.log("Account status:", accountStatus);

    if (accountStatus === "Acc_Deleted") {
      toast.error("Your account has been deleted."); // Show error toast
      // Clear cookies and local storage, and navigate to the login page
      Cookies.remove("Username");
      Cookies.remove("Password");
      Cookies.remove("authToken"); // Remove the auth token from cookies
      localStorage.clear();

      // Redirect to the login page
      window.location.href = "/"; // Redirect to login or desired page

      // Return an object indicating the user was logged out
      return { status: 401, token: null };
    } else if (accountStatus === "Acc_Deactive") {
      toast.warn("Your account is deactivated."); // Show warning toast
      // Clear cookies and local storage, and navigate to the login page
      Cookies.remove("Username");
      Cookies.remove("Password");
      Cookies.remove("authToken"); // Remove the auth token from cookies
      localStorage.clear();

      // Redirect to the login page
      window.location.href = "/"; // Redirect to login or desired page

      // Return an object indicating the user was logged out
      return { status: 401, token: null };
    }

    // Store the token in cookies
    Cookies.set("authToken", token, { expires: 7 }); // Store token for 7 days

    // Debugging: Check if token is stored in cookies
    console.log("Stored token in cookies:", Cookies.get("authToken"));

    // Return an object with both the status and token
    return { status: response.status, token };
  } catch (error) {
    // Handle specific error if the token is invalid
    if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
      Cookies.remove("authToken"); // Remove token if invalid
      window.location.href = "/"; // Redirect to the home page
    }

    // Return error status and null token
    return { status: error.response?.status, token: null };
  }
};
export const updateInsuranceStartDate = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vechicle_registration/UpdateVehicleRegistrationInsuranceSdate`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });


    return response; // Return the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const updateInsuranceEndDate = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vechicle_registration/UpdateVehicleRegistrationInsuranceEdate`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });


    return response; // Return the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const updateTInsuranceStartDate = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vechicle_registration/UpdateVehicleRegistrationInsuranceSdate`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });


    return response; // Return the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const updateTInsuranceEndDate = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vechicle_registration/upd-thirdparty-insurance-edate`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });


    return response; // Return the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
// battery add
export const addBattery = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/battery`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    console.log(response);

    return response; // Return the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const addBatterywise = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/dropdown/dealer-vehicle-wise-battery`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    console.log(response);

    return response; // Return the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
// battery list
export const viewBattery = async (data) => {
  try {
    const response = await axios.get(
      `${API_URI2}/battery/getcolumnchoose?${
        data.page ? "page=" + data.page + "&" : ""
      }limit=${data.limit ? data.limit : ""}&filter=${
        data.filter ? data.filter : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const deleteBattery = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/battery/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// Get Battery
export const getBattery = async (id) => {
  try {
    const response = await axios.get(`${API_URI2}/battery/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    console.log("battery", response.data);

    return response.data;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// update Battery
export const updateBattery = async (id, batteryData) => {
  try {
    const response = await axios.put(`${API_URI2}/battery/${id}`, batteryData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// serialnumber
export const serialNumber = async (BatteryID, serialNumber) => {
  console.log(decryption(serialNumber));
  try {
    const response = await axios.post(
      `${API_URI2}/battery/serialnumber`,
      { BatteryID, SerialNumber: serialNumber },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : "Error updating battery"
    );
  }
};
// imeiNumber
export const imeiNumber = async (BatteryID, imeiNumber) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/imei_number`,
      { BatteryID, IMEINumber: imeiNumber },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "Error updating battery IMEI number"
    );
  }
};
// simnumber
export const simNumber = async (BatteryID, imeiNumber) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/sim_number`,
      { BatteryID, SIMNumber: imeiNumber },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "Error updating battery IMEI number"
    );
  }
};
// BTCode
export const btCode = async (BatteryID, btCode) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/bt_code`,
      { BatteryID, BTCode: btCode },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "Error updating battery BT code"
    );
  }
};
//battery column chooser
export const BatteryColumnChooser = async (columnData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/savecolumnchoose`,
      {
        data: columnData,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};

// add subscription
export const addSubscription = async (SubscriptionData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/subscription`,
      SubscriptionData,
      {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// Subscription view
export const SubscriptionView = async (data) => {
  try {
    const response = await axios.get(
      `${API_URI2}/subscription?${
        data.page ? "page=" + data.page + "&" : ""
      }limit=${data.limit ? data.limit : ""}&filter=${
        data.filter ? data.filter : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// subscription column chooser
export const SubscriptionColumnChooser = async (columnData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/subscription/savecolumnchoose`,
      {
        data: columnData,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const fetchVehicleData = async (id) => {
  try {
    const response = await axios.get(
      `https://api.ceyoneev.com/api/v2/admin/vechicle_registration/${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.Data[0];
  } catch (error) {
    console.error("Error fetching vehicle data:", error);
    throw error;
  }
};
// get subscription
export const getSubscription = async (id) => {
  try {
    const response = await axios.get(`${API_URI2}/subscription/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response.data;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// update subscription
// export const updateSubscription = async (id, SubscriptionData) => {
//     try {
//         const response = await axios.put(`${API_URI2}/subscription/${id}`, SubscriptionData, {
//             headers: {
//                 'Content-Type': 'application/json',
//                 "Authorization": `Bearer ${Cookies.get('authToken')}`, // Retrieve token from cookies
//             }
//         });

//         return response; // Return the entire response object
//     } catch (error) {
//         throw new Error(error.response ? error.response.data.message : 'Error updating subscription');
//     }
// };
export const updateSubscription = async (id, SubscriptionData) => {
  try {
    const response = await axios.put(
      `${API_URI2}/subscription/${id}`,
      SubscriptionData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response; // Return the entire response object
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// delete subscription

export const deleteSubscription = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/subscription/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    if (response.status === 200) {
      return response.data; // Return the response data on successful deletion
    } else {
      throw new Error("Failed to delete subscription"); // Throw error if status is not 200
    }
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Useradd_api = async (formData, permission) => {
  console.log("user add ", formData, API_URI2);
  try {
    const response = await axios.post(
      API_URI2 + "/user",
      {
        Username: formData.Username,
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        EmailID: encryption(formData.EmailID),
        Password: encryption(formData.Password),
        PhoneNum1: encryption(formData.PhoneNum1),
        PhoneNum2: formData.PhoneNum2 ? encryption(formData.PhoneNum1) : "", // Conditional encryption
        Address: formData.Address,
        IsActive: true,
        IsOperator: true,
        RoleID: formData.RoleID,
        UserAccessData: permission,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      }
    );

    console.log("API response", response);
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Useredit_api = async (id, formData, permission) => {
  console.log("user add ", formData, API_URI2);
  try {
    const response = await axios.put(
      API_URI2 + `/user/${id}`,
      {
        Username: formData.Username,
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        EmailID: encryption(formData.EmailID),
        Password: encryption(formData.Password),
        PhoneNum1: encryption(formData.PhoneNum1),
        PhoneNum2: formData.PhoneNum2 ? encryption(formData.PhoneNum2) : "", // Use PhoneNum2 here
        Address: formData.Address,
        IsActive: formData.IsActive,
        IsOperator: formData.IsOperator,
        RoleID: formData.RoleID,
        UserAccessData: permission,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("UPDATE USER--------------------------", response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;
      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const User_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/user/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const User_list_get = async (data) => {
  try {
    const response = await axios.get(
      `${api_base}/user?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Delete_User_by_id = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
//for station data add
export const Stationa_add_api = async (formData) => {
  console.log("station add ", formData, API_URI2);
  try {
    const response = await axios.post(
      API_URI2 + "/station",
      {
        StationName: formData.StationName,
        StationSName: formData.StationSName,
        ContactPersonName: formData.ContactPersonName,
        ContactPersonNum1: encryption(formData.ContactPersonNum1),
        // Encrypt only if not empty, otherwise set as empty string
        ContactPersonNum2: formData.ContactPersonNum2
          ? encryption(formData.ContactPersonNum2)
          : "",
        // Encrypt only if not empty, otherwise set as empty string
        ContactPersonEmailID: formData.ContactPersonEmailID
          ? encryption(formData.ContactPersonEmailID)
          : "",
        ContactPersonAddress: formData.ContactPersonAddress,
        AddressLine1: formData.AddressLine1,
        AddressLine2: formData.AddressLine2,
        AddressLine3: formData.AddressLine3,
        Pincode: formData.Pincode,
        AreaID: formData.AreaID,
        CityID: formData.CityID,
        StateID: formData.StateID,
        Latitude: formData.Latitude,
        Longitude: formData.Longitude,
        QRCode: formData.QRCode,
        IsActive: formData.IsActive,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// export const ColorAdd_api = async (formData) => {
//     try {
//         const cleanedColorName = formData.ColorName.trim().replace(/\s+/g, " ");
//         const cleanedColorSName = formData.ColoSrName.trim().replace(/\s+/g, " ");
//         const response = await axios.post(API_URI2 + "/color_master", {
//             ColorName: cleanedColorName,
//             ColorSName: cleanedColorSName,
//             ColorCode: formData.ColorCode,
//             IsActive: true
//         },
//             {
//                 headers: {
//                     "Authorization": `Bearer ${Cookies.get('authToken')}`, // Retrieve token from cookies
//                     "Content-Type": "application/json"
//                 },
//             }
//         )
//         return response
//     } catch (error) {
//          // Check if the error is an Axios error and handle it
//          if (error?.response) {
//             const errorData = error.response.data;
//             if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
//                 Cookies.remove('authToken'); // Ensure you remove the correct token
//                 Cookies.remove('Username');
//                 Cookies.remove('Password');
//                 Cookies.remove('previousRoute'); // Clear the stored route on logout
//                 localStorage.clear(); // Clear local storage
//                 window.location.href = "/"; // Redirect to the login page
//             } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
//                 Cookies.remove('authToken'); // Ensure you remove the correct token
//                 localStorage.clear(); // Clear local storage
//                 window.location.href = '/'; // Redirect to the login page
//             }
//         } else {
//             console.error("Unexpected error: ", error);
//         }
//         return error;
//     }
// }
export const Areaadd_api = async (formData) => {
  try {
    const cleanedAreaName = formData.AreaName.trim().replace(/\s+/g, " ");
    const response = await axios.post(
      API_URI2 + "/area",
      {
        AreaName: cleanedAreaName,
        CityID: formData.CityID,
        StateID: formData.StateID,
        IsActive: true,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Area_list_get = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/area/?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Area_columnsave_Api = async (columnData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/area/savecolumnchoose`,
      {
        data: columnData,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};

export const Area_delete_api = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/area/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Areaedit_api = async (id, formData) => {
  console.log("user add ", formData, API_URI2);
  try {
    const response = await axios.put(
      API_URI2 + `/area/${id}`,
      {
        AreaName: formData.AreaName,
        CityID: formData.CityID,
        StateID: formData.StateID,
        IsActive: formData.IsActive,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Area_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/area/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Station_column_Api = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/station/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response; // Return the response data
  } catch (error) {
    console.error("API call error:", error); // Use console.error for errors
    throw error; // Rethrow the error to be caught in handleSave
  }
};

export const Station_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/station/?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Station_delete_api = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/station/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const stationedit_api = async (id, formData) => {
  console.log("station addd ", formData, API_URI2, id);

  // Check if ContactPersonNum1 and ContactPersonNum2 are populated
  console.log("ContactPersonNum1:", formData.ContactPersonNum1);
  console.log("ContactPersonNum2:", formData.ContactPersonNum2);

  try {
    const response = await axios.put(
      API_URI2 + `/station/${id}`,
      {
        StationName: formData.StationName,
        StationSName: formData.StationSName,
        ContactPersonName: formData.ContactPersonName,
        ContactPersonNum1: encryption(formData.ContactPersonNum1),
        // Encrypt only if not empty, otherwise set as empty string
        ContactPersonNum2: formData.ContactPersonNum2
          ? encryption(formData.ContactPersonNum2)
          : "",
        // Encrypt only if not empty, otherwise set as empty string
        ContactPersonEmailID: formData.ContactPersonEmailID
          ? encryption(formData.ContactPersonEmailID)
          : "",
        ContactPersonAddress: formData.ContactPersonAddress,
        AddressLine1: formData.AddressLine1,
        AddressLine2: formData.AddressLine2,
        AddressLine3: formData.AddressLine3,
        Pincode: formData.Pincode,
        AreaID: formData.AreaID,
        CityID: formData.CityID,
        StateID: formData.StateID,
        Latitude: formData.Latitude,
        Longitude: formData.Longitude,
        QRCode: formData.QRCode,
        IsActive: formData.IsActive,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("API response:", response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

//station edit

export const station_get_by_id = async (id) => {
  console.log("id", id);
  try {
    const response = await axios.get(API_URI2 + `/station/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
//customer list Apis

export const Customer_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/customer/?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Customer_delete_api = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
//station columnchooser
export const station_configration_api = async (data) => {
  console.log("colukdnkdj", data); // Use console.log here
  try {
    const response = await axios.put(
      `${API_URI2}/station/updateconfiguration`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response; // Return the response data
  } catch (error) {
    console.error("API call error:", error); // Use console.error for errors
    throw error; // Rethrow the error to be caught in handleSave
  }
};
//customer ADD api
export const Customer_add_api = async (formData) => {
  console.log("user add ", formData);
  try {
    const response = await axios.post(
      API_URI2 + "/customer",
      {
        MarriageStatus: formData.MarriageStatus,
        IsCompany: formData.IsCompany,
        CompanyType: formData.CompanyType,
        CompanyName: formData.CompanyName,
        GSTIN: formData.GSTIN,
        RegisteredAddress: formData.RegisteredAddress,
        CustomerName: formData.CustomerName,
        MobileNum: encryption(formData.MobileNum),
        EmailID: encryption(formData.EmailID),
        Address: formData.Address,
        Pincode: formData.Pincode,
        AreaID: formData.AreaID,
        CityID: formData.CityID,
        StateID: formData.StateID,
        AadharCardNumber: encryption(formData.AadharCardNumber),
        PAN: encryption(formData.PAN),
        DrivingLicenseNumber: encryption(formData.DrivingLicenseNumber),
        HasParent: formData.HasParent,
        // ParentID: formData.ParentID,
        IsActive: formData.IsActive,
        CustomerID: formData.CustomerID,
        ModelID: formData.ModelID,
        ColourID: formData.ColourID,
        BatteryID: formData.BatteryID, // Use the selected battery ID from formData
        DOB: formData.DOB,
        AnniversaryDate: formData.AnniversaryDate,
        VehicleNumber: encryption(formData.VehicleNumber),
        ChasisNumber: encryption(formData.ChasisNumber),
        EngineNumber: encryption(formData.EngineNumber),
        InsuranceYear: formData.InsuranceYear,
        ThirdPartyInsuranceYear: formData.ThirdPartyInsuranceYear,
        // ModelName: formData.ModelID,
        DealerID: formData.DealerID, // Ensure this is correctly set
        InsuranceStartDate: formData.InsuranceStartDate,
        // InsuranceEndDate: formData.InsuranceEndDate,
        ThirdPartyInsuranceStartDate: formData.ThirdPartyInsuranceStartDate,
        // ThirdPartyInsuranceEndDate: formData.ThirdPartyInsuranceEndDate,
        BikeRegistrationDate: formData.BikeRegistrationDate,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Customer_edit_api = async (id, formData) => {
  console.log("customer edit", formData);
  try {
    const response = await axios.put(
      API_URI2 + `/customer/${id}`,
      {
        DOB: formData.DOB,
        MarriageStatus: formData.MarriageStatus,
        AnniversaryDate: formData.AnniversaryDate,
        IsCompany: formData.IsCompany,
        CompanyType: formData.CompanyType, // Make sure it's 'CompanyType'
        CompanyName: formData.CompanyName,
        GSTIN: formData.GSTIN,
        RegisteredAddress: formData.RegisteredAddress,
        CustomerName: formData.CustomerName,
        MobileNum: encryption(formData.MobileNum),
        EmailID: encryption(formData.EmailID),
        Address: formData.Address,
        Pincode: formData.Pincode,
        AreaID: formData.AreaID,
        CityID: formData.CityID,
        ColourID: formData.ColourID,
        StateID: formData.StateID,
        AadharCardNumber: encryption(formData.AadharCardNumber),
        PAN: encryption(formData.PAN),
        DrivingLicenseNumber: encryption(formData.DrivingLicenseNumber),
        HasParent: formData.HasParent,
        // ParentID: formData.ParentID,
        IsActive: formData.IsActive,
        DealerID: formData.DealerID, // Ensure this is correctly set
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Vehicle_registration_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/vechicle_registration?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const vehicle_registration_column_chooser = async (data) => {
  try {
    // console.log(JSON.parse(data), "APi side");
    const response = await axios.post(
      API_URI2 + `/vechicle_registration/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
      window.location.href = "/";
    }
    return error;
  }
};

export const delete_vehicle_registration = async (id) => {
  try {
    const response = await axios.delete(
      `${API_URI2}/vechicle_registration/${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_registration_add = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/vechicle_registration`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const registration_get_by_id = async (id) => {
  try {
    const response = await axios.get(
      API_URI2 + `/vechicle_registration/${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_registration_update = async (data, id) => {
  try {
    const response = await axios.put(
      `${API_URI2}/vechicle_registration/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const vehicle_Model_add = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vehicle_model`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const model_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/vehicle_model/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_Model_update = async (data, id) => {
  try {
    const response = await axios.put(`${API_URI2}/vehicle_model/${id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Vehicle_model_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/vehicle_model?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_model_column_chooser = async (data) => {
  try {
    const response = await axios.post(
      API_URI2 + `/vehicle_model/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const delete_vehicle_model = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/vehicle_model/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
// Vehicle Color
export const Vehicle_colour_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/vehicle_colour?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const Vehicle_type_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/vehicle_type?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const vehicle_colour_add = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vehicle_colour`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_type_add = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/vehicle_type`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_colour_update = async (data, id) => {
  try {
    const response = await axios.put(`${API_URI2}/vehicle_colour/${id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_type_update = async (data, id) => {
  try {
    const response = await axios.put(`${API_URI2}/vehicle_type/${id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_colour_delete = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/vehicle_colour/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_type_delete = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/vehicle_type/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_colour_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/vehicle_colour/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_type_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/vehicle_type/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_colour_column_chooser = async (data) => {
  try {
    const response = await axios.post(
      `https://api.ceyoneev.com/api/v2/admin/vehicle_colour/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const vehicle_type_column_chooser = async (data) => {
  try {
    const response = await axios.post(
      `https://api.ceyoneev.com/api/v2/admin/vehicle_type/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const role_column_chooser = async (data) => {
  try {
    const response = await axios.post(
      `https://api.ceyoneev.com/api/v2/admin/role/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const colour_list_api = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/color_master/getcolumnchoose?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const color_add = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/color_master`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const color_update = async (data, id) => {
  try {
    const response = await axios.put(`${API_URI2}/color_master/${id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const color_delete = async (id) => {
  try {
    const response = await axios.delete(
      `${API_URI2}/color_master/delete-color/${id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const colour_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/color_master/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const colour_column_chooser = async (data) => {
  try {
    const response = await axios.post(
      `https://api.ceyoneev.com/api/v2/admin/color_master/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
// End
export const saveColumnChooser = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/user/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};

//customer column chooser save data Api
export const Customer_columnsave_Api = async (columnData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/customer/saveconfiguration`,
      {
        data: columnData,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

//city list

export const city_list_get = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/country/city`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

//state
export const state_list_get = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/country/state`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
//country dropdown
export const Country_list_get = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/country`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
//area dropdown
export const Area_dropdown_list = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/area/getalldata`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,

        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// dealer master Apis

export const Dealeradd_api = async (formData) => {
  console.log("dealer add", formData);
  try {
    const response = await axios.post(
      `${API_URI2}/dealer`,
      {
        DealerName: formData.DealerName,
        CompanyType: formData.CompanyType,
        GSTIN: formData.GSTIN,
        RegisteredAddress: formData.RegisteredAddress,
        CompanyName: formData.CompanyName,
        Pincode: formData.Pincode,
        AreaID: formData.AreaID,
        CityID: formData.CityID,
        StateID: formData.StateID,
        EmailID: formData.EmailID,
        MobileNum: formData.MobileNum,
        IsActive: formData.IsActive,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response);
    return response; // Ensure this returns the response
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Dealeredit_api = async (id, formData) => {
  console.log("user add ", formData);
  try {
    const response = await axios.put(
      API_URI2 + `/dealer/${id}`,
      {
        DealerName: formData.DealerName,
        CompanyType: formData.CompanyType,
        GSTIN: formData.GSTIN,
        RegisteredAddress: formData.RegisteredAddress,
        CompanyName: formData.CompanyName,
        Pincode: formData.Pincode,
        AreaID: formData.AreaID,
        CityID: formData.CityID,
        StateID: formData.StateID,
        EmailID: formData.EmailID,
        MobileNum: formData.MobileNum,
        IsActive: formData.IsActive,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Dealer_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/dealer/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Dealer_list_get = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/dealer?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Delete_Dealer_by_id = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/dealer/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Delear_column_save = async (columnData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dealer/savecolumnchoose`,
      {
        data: columnData,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const Customer_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const station_getalldata = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/station/getalldata`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const Area_getalldata = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/area/getalldata`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const drop_state = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/statelist`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const drop_city = async (state_id) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/statewisecity`,
      {
        StateID: state_id,
      }, // Empty body if not needed
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// Fetch areas
export const drop_area = async (city_id) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/citywisearea`,
      {
        CityID: city_id,
      }, // Empty body if not needed
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const forgot_pass = async (EmailID) => {
  try {
    const response = await axios.post(
      `${API_URI2}/auth/forget_password_email_verification`,
      {
        EmailID: EmailID, // Changed to "EmailID"
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const otp_api = async (otp, id) => {
  try {
    const response = await axios.post(
      `${API_URI2}/auth/forget_password_otp_check`,
      {
        OTP: otp,
        UserID: id,

        // Username: encryption(Username),
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const reset_pass = async (OTP, Username, password) => {
  try {
    const encryptedPassword = encryption(password);
    const response = await axios.post(
      `${API_URI2}/auth/update_password_data`,
      {
        OTP: OTP,
        UserID: Username,
        Password: encryptedPassword,
        ConfirmPassword: encryptedPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};
export const Battery_allocation_list = async (data) => {
  try {
    console.log("API call starting with data:", data);
    const response = await axios.post(
      `${API_URI2}/battery/battery_allocation_list`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies

          "Content-Type": "application/json",
        },
      }
    );
    console.log("API response received:", response);
    return response;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

export const Battery_allocation = async (formData) => {
  console.log("Battery add ", formData, API_URI2);

  try {
    // Ensure data is in the correct format
    const response = await axios.post(
      `${API_URI2}/battery/battery_allocation_status`,
      {
        BatteryID: formData.BatteryID,
        CustomerID: formData.CustomerID,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("API response", response);
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

// export const Sub_managment = async (data) => {
//     try {
//         console.log('Data sent to API:', data);
//         const response = await axios.post(`${API_URI2}/subscription/customer_subscription`, data, {
//             headers: {
//                 "Authorization": `Bearer ${Cookies.get('authToken')}`, // Retrieve token from cookies

//                 'Content-Type': 'application/json'
//             }
//         });
//         console.log('API response:', response.data);
//         return response;
//     } catch (error) {
//         // Check if the error is an Axios error and handle it
//         if (error?.response) {
//             const errorData = error.response.data;

//             // Handle specific error for invalid token

//         } else {
//             // Handle cases where the error does not have a response
//             console.error("Unexpected error: ", error);
//         }

//         // Return the error for further handling or logging
//         return error;
//     }
// };

export const Sub_managment = async (formData) => {
  try {
    console.log("Data sent to API:", formData);
    const response = await axios.post(
      `${API_URI2}/subscription/customer_subscription`,
      {
        CustomerID: formData.CustomerID,
        SubscriptionID: formData.SubscriptionID,
        SubscriptionName: formData.SubscriptionName,
        SubscriptionSName: formData.SubscriptionSName,
        ValidityDays: formData.ValidityDays,
        NoOfSwaps: formData.NoOfSwaps,
        Charge: formData.Charge,
        VehicleRegistrationID: formData.VehicleRegistrationID,
        PaymentType: formData.PaymentType,
        TransactionReferenceNumber: formData.TransactionReferenceNumber || "",
        ChequeNumber: formData.ChequeNumber || "",
        BankName: formData.BankName || "",
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies

          "Content-Type": "application/json",
        },
      }
    );
    console.log("API response:", response.data);
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Vehicle_model_drop = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/vehicle-model-dropdown`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.ModelData;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;

      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const Vehicle_model_type_drop = async (data) => {
  try {
    const response = await axios.post(
      `https://api.ceyoneev.com/api/v2/admin/vehicle_type/unused_model_type`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;

      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const Vehicle_color_drop = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/color-master-list`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.ColorData;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;

      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const vehicle_colour_drop = async () => {
    try {
        const response = await axios.post(`${API_URI2}/dropdown/vehicle-color-dropdown`, {}, {
            headers: {
                "Authorization": `Bearer ${Cookies.get('authToken')}`, // Retrieve token from cookies
                'Content-Type': 'application/json'
            }
        });
        return response.data.ColorData; // Assuming the data is in response.data.ColorData
    } catch (error) {
        console.error('Error fetching vehicle colors:', error);
        throw error;
    }
};

export const Vehicle_Battery_drop = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/fress-batery-list`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.BatteryData;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;

      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Company_type = async () => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/company-type-dropdown`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("API Response:", response.data); // Log the response to verify structure
    return response.data.CompayTypeData; // Ensure correct field is being returned
  } catch (error) {
    console.error("Error fetching company types:", error);
    throw error;
  }
};
export const Parent_drop = async (customer_id) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/customer-parent-dropdown`,
      { CustomerID: customer_id },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Ensure you return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const Maintenance_api = async (data) => {
  try {
    const response = await axios.get(
      `${API_URI2}/maintanance/?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("response", response); // Ensure you return the data part of the response

    return response;
  } catch (error) {
    throw error;
  }
};

export const Maintenence_add = async (formData) => {
  console.log("user add ", formData, API_URI2);
  try {
    const response = await axios.post(
      API_URI2 + "/maintanance",
      {
        Title: formData.Title,
        Description: formData.Description,
        MaintanaceEndDate: formData.MaintanaceEndDate,
        MaintanaceStartDate: formData.MaintanaceStartDate,
        MaintanaceStatus: formData.MaintanaceStatus,
        CustomerMaintanace: formData.CustomerMaintanace,
        OperatorMaintanace: formData.OperatorMaintanace,
        DealerMaintanace: formData.DealerMaintanace,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      }
    );

    console.log("API response", response);
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Maintenence_edit = async (id, formData) => {
  console.log("user add ", formData, API_URI2);
  try {
    const response = await axios.put(
      API_URI2 + `/maintanance/${id}`,
      {
        Title: formData.Title,
        Description: formData.Description,
        MaintanaceEndDate: formData.MaintanaceEndDate,
        MaintanaceStartDate: formData.MaintanaceStartDate,
        MaintanaceStatus: formData.MaintanaceStatus,
        CustomerMaintanace: formData.CustomerMaintanace,
        OperatorMaintanace: formData.OperatorMaintanace,
        DealerMaintanace: formData.DealerMaintanace,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Maintenence_get_by_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/maintanance/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Main_delete = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/maintanance/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Main_ColumnChooser = async (data) => {
  try {
    // Debug payload to check structure
    console.log("Payload being sent to API:", data);

    const response = await axios.post(
      `${API_URI2}/maintanance/savecolumnchoose`,
      {
        data: data,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies

          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};

export const myprofile_update = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/MyProfile`,
      {
        Username: formData.Username,
        EmailID: encryption(formData.EmailID),
        Password: encryption(formData.Password),
        PhoneNum1: encryption(formData.PhoneNum1),
        PhoneNum2: formData.PhoneNum2 ? encryption(formData.PhoneNum2) : "", // Use PhoneNum2 here
        Address: formData.Address,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const get_myprofile = async () => {
  try {
    const response = await axios.get(`${API_URI2}/myprofile`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (error.response.status === 401) {
        // Invalid token or authentication issue
        console.error("Invalid token or authentication issue.");
        Cookies.remove("authToken"); // Clear invalid token
        // Optionally redirect to login page
        window.location.href = "/login"; // Adjust the URL as necessary
      } else {
        console.error("Error response:", errorData);
      }
    } else {
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Duplicate_api = async (formData) => {
  // console.log("user add ", formData, API_URI2);
  try {
    const response = await axios.post(
      API_URI2 + "/duplicate-check",
      {
        // Dynamically pass values from formData
        ID: formData.ID,
        Table: formData.Table,
        ColumnName: formData.ColumnName,
        DuplicateKey: formData.DuplicateKey,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      }
    );

    console.log("response", response);
    return response;
  } catch (error) {
    if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
      window.location.href = "/";
    }
    return error;
  }
};

export const battery_assign = async (formData) => {
  console.log("user add ", formData);
  try {
    const response = await axios.post(
      API_URI2 + "/battery/battery_allocation_status",
      {
        CustomerID: formData.CustomerID,
        BatteryID: formData.BatteryID,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
        },
      }
    );

    console.log("response", response);
    return response;
  } catch (error) {
    if (error?.response?.data?.ErrorCode === "INVALIDTOKEN") {
      window.location.href = "/";
    }
    return error;
  }
};

export const Sub_view = async (data) => {
  try {
    console.log("API call starting with data:", data);
    const response = await axios.post(
      `${API_URI2}/subscription/get_all_list`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies

          "Content-Type": "application/json",
        },
      }
    );
    console.log("API response received:", response);
    return response;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};
export const SubscriptionViewdata = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/subscription/get_customer_subscription_status`,
      {
        CustomerID: formData.CustomerID,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log("response", response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Get_Batteries = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_battery_assign_dealer`,
      {
        DealerID: formData.DealerID,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const new_vehicle = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/vechicle_registration/get_all_list`,
      {
        CustomerID: formData.CustomerID,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Deassign_Dealer_battrey = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_battery_assign_dealer_assign_deassign?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        DealerID: data.DealerID,
        filter: data.filter,
        limit: data.limit,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const NewAssign_Dealerbattrey = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_new_battery?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        DealerID: data.DealerID,
        filter: data.filter,
        limit: data.limit,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const Assign_Dealerbattrey = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_battery_assign_dealer?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        DealerID: data.DealerID,
        filter: data.filter,
        limit: data.limit,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Assign_battery_Dealer = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/battery_assign_dealer`,
      {
        DealerID: formData.DealerID,
        Assing_BatteryID: JSON.stringify(formData.Assing_BatteryID), // Ensure this is properly formatted
        Deassing_BatteryID: JSON.stringify(formData.Deassing_BatteryID),
        ReasonForunassign: formData.ReasonForunassign, // Ensure this is properly formatted
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response data:", response.data);
    return response.data;
  } catch (error) {
    console.error("API call error:", error.message);
    if (error.response) {
      console.error("Error response data:", error.response.data);
    }
    return Promise.reject(error);
  }
};

export const Newassign_stationbattery = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_new_station_battery?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        StationID: data.StationID,
        filter: data.filter,
        limit: data.limit, // Include the search query in the request
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log("response", response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Assign_stationbattery = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_assign_station_battery?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        StationID: data.StationID,
        filter: data.filter,
        limit: data.limit,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    ); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};
export const Deassign_stationbattery = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_assign_station_battery_deassing?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        StationID: data.StationID,
        filter: data.filter,
        limit: data.limit,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Assign_Batteries_station = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/battery_assign_station`,
      {
        StationID: formData.StationID,
        Assing_BatteryID: JSON.stringify(formData.Assing_BatteryID), // Ensure this is properly formatted
        Deassing_BatteryID: JSON.stringify(formData.Deassing_BatteryID),
        ReasonForunassign: formData.ReasonForunassign, // Ensure this is properly formatted
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("API call error:", error.message);
    if (error.response) {
      console.error("Error response data:", error.response.data);
    }
    return Promise.reject(error);
  }
};

export const SubscriptionVieww = async (formData, regid) => {
  try {
    const response = await axios.post(
      `${API_URI2}/subscription/get_customer_subscription_status`,
      {
        CustomerID: formData.CustomerID,
        VehicleRegistrationID: regid,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log("response", response); // Check what the response looks like
    return response.data; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Sub_disable = async (formData) => {
  try {
    console.log("API call starting with formData:", formData);
    const response = await axios.post(
      `${API_URI2}/subscription/customer_subscription_status`,
      {
        CustomerSubscriptionID: formData.CustomerSubscriptionID,
        IsActive: formData.IsActive,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies

          "Content-Type": "application/json",
        },
      }
    );
    console.log("API response received:", response);
    return response;
  } catch (error) {
    console.error("Error during API call:", error);
    throw error;
  }
};

export const Vehicle_model_dropp_color = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/model-wise-vehicle-colors`,
      {
        VehicleModelID: formData.VehicleModelID,
        ColorID: formData.ColorID,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response of model", response);
    return response.data.ColorData; // Return the full data object here
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken");
        window.location.href = "/"; // Redirect to login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Dealer_dropdown = async () => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/dealerlist`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response; // Return the full data object here
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken");
        window.location.href = "/"; // Redirect to login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const custom_dealer_battery = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/dealerwise-vehicle-battery`,
      {
        DealerID: formData.DealerID,
        EngineNumber: encryption(formData.EngineNumber),
        ChasisNumber: encryption(formData.ChasisNumber),
        VehicleNumber: encryption(formData.VehicleNumber),
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response of Dealer", response);
    return response.data.AssignedData; // Return the full data object here
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken");
        window.location.href = "/"; // Redirect to login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const Customer_vehicle = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/customer/customer_wise_vehicle`,
      {
        CustomerID: formData.CustomerID,
        DealerID: formData.DealerID,
        VehicleNumber: formData.VehicleNumber,
        ChasisNumber: formData.ChasisNumber,
        EngineNumber: formData.EngineNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    console.log(response); // Check what the response looks like
    return response; // Return only the data from the response
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

// export const Customer_reg_get = async (data) => {
//     try {
//         const response = await axios.post(`${API_URI2}/customer/GetCustomerReg?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,

//             {
//                 headers: {
//                     "Authorization": `Bearer ${Cookies.get('authToken')}`, // Retrieve token from cookies
//                     "Content-Type": "application/json"
//                 },
//             }
//         );
//         console.log(response)

//         return response;
//     } catch (error) {
//         if (error?.response) {
//             const errorData = error.response.data;
//             console.error("Error from API:", errorData);
//         } else {
//             console.error("Unexpected error: ", error);
//         }
//         return error;
//     }
// };

export const Customer_reg_get = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/customer/GetCustomerReg?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {}, // Empty object for body since this is a GET-like operation
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log(response);
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;
      console.error("Error from API:", errorData);
    } else {
      console.error("Unexpected error:", error);
    }
    return error;
  }
};

export const Coupen_add = async (data) => {
  try {
    const response = await axios.post(`${API_URI2}/coupon`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;
      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Get_coupon_id = async (id) => {
  try {
    const response = await axios.get(`${API_URI2}/coupon/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });

    console.log("battery", response.data);

    return response.data;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const view_coupon = async (data) => {
  try {
    const response = await axios.get(
      `${API_URI2}/coupon?${data.page ? "page=" + data.page + "&" : ""}limit=${
        data.limit ? data.limit : ""
      }&filter=${data.filter ? data.filter : ""}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      // if (errorData?.message === "Invalid token" || errorData?.ErrorCode === "INVALIDTOKEN") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     Cookies.remove('Username');
      //     Cookies.remove('Password');
      //     Cookies.remove('previousRoute'); // Clear the stored route on logout
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = "/"; // Redirect to the login page
      // } else if (errorData?.account_status === "Acc_Deleted" || errorData?.account_status === "Acc_Deactive") {
      //     Cookies.remove('authToken'); // Ensure you remove the correct token
      //     localStorage.clear(); // Clear local storage
      //     window.location.href = '/'; // Redirect to the login page
      // }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const coupon_update = async (id, data) => {
  try {
    const response = await axios.put(`${API_URI2}/coupon/${id}`, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    console.log("responsce coupon", response);

    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Coupon_delete = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/coupon/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const Coupon_column = async (columnData) => {
  try {
    const response = await axios.post(
      `${API_URI2}/coupon/savecolumnchoose`,
      {
        data: columnData,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const questions_for_unassign = async () => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_deassign_dealer_question`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const questions_for_unassignStation = async () => {
  try {
    const response = await axios.post(
      `${API_URI2}/battery/get_deassign_station_question`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const deassign_remark = async (data) => {
  const formData = new FormData();
  formData.append("QuestionOption", data.QuestionOption);
  formData.append("Remark", data.ReasonForunassign);
  formData.append("BatteryID", JSON.stringify(data.BatteryID));
  formData.append(
    "BatteryDealerAssignID",
    JSON.stringify(data.BatteryDealerAssignID)
  );
  try {
    const response = await axios.post(
      `${API_URI2}/battery/battery_deassign_dealer_remark`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "multipart/formdata",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const page_list = async () => {
  try {
    const response = await axios.post(
      `${API_URI2}/role/page-list`,
      {},
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "multipart/formdata",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const role_add = async (data, permission) => {
  const formData = new URLSearchParams();
  formData.append("RoleName", data.RoleName);
  formData.append("RoleSName", data.RoleSName);
  formData.append("Remark", data.Remark);
  formData.append("IsActive", data.IsActive);
  formData.append("PermissionData", JSON.stringify(permission));
  try {
    const response = await axios.post(`${API_URI2}/role`, formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
export const role_get_id = async (id) => {
  try {
    const response = await axios.get(API_URI2 + `/role/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const role_list = async (data) => {
  try {
    const response = await axios.get(
      API_URI2 +
        `/role/getcolumnchoose?page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        },
      }
    );
    return response;
  } catch (error) {
    // Check if the error is an Axios error and handle it
    if (error?.response) {
      const errorData = error.response.data;

      // Handle specific error for invalid token
      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        Cookies.remove("Username");
        Cookies.remove("Password");
        Cookies.remove("previousRoute"); // Clear the stored route on logout
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        localStorage.clear(); // Clear local storage
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      // Handle cases where the error does not have a response
      console.error("Unexpected error: ", error);
    }

    // Return the error for further handling or logging
    return error;
  }
};

export const role_update = async (data, permission, id) => {
  const formData = new URLSearchParams();
  formData.append("RoleName", data.RoleName);
  formData.append("RoleSName", data.RoleSName);
  formData.append("Remark", data.Remark);
  formData.append("IsActive", data.IsActive);
  formData.append("PermissionData", JSON.stringify(permission));
  try {
    const response = await axios.put(`${API_URI2}/role/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};

export const role_delete = async (id) => {
  try {
    const response = await axios.delete(`${API_URI2}/role/delete-roll/${id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
      },
    });
    return response; // Assuming you are interested in the response data
  } catch (error) {
    return error;
  }
};
// export const Area_columnsave_Api = async (columnData) => {
//     try {
//         const response = await axios.post(`${API_URI2}/area/savecolumnchoose`, {
//             "data": columnData
//         },
//             {
//                 headers: {
//                     "Authorization": `Bearer ${Cookies.get('authToken')}`, // Retrieve token from cookies
//                     'Content-Type': 'application/json'
//                 }
//             });
//         return response;
//     } catch (error) {
//         console.error("Error saving column chooser", error.message);
//         throw error;
//     }
// };
export const role_drop = async (data) => {
  try {
    const response = await axios.post(
      `${API_URI2}/dropdown/roll-list`,
      { data: data },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    if (error?.response) {
      const errorData = error.response.data;

      if (
        errorData?.message === "Invalid token" ||
        errorData?.ErrorCode === "INVALIDTOKEN"
      ) {
        window.location.href = "/"; // Redirect to the login page
      } else if (
        errorData?.account_status === "Acc_Deleted" ||
        errorData?.account_status === "Acc_Deactive"
      ) {
        Cookies.remove("authToken"); // Ensure you remove the correct token
        window.location.href = "/"; // Redirect to the login page
      }
    } else {
      console.error("Unexpected error: ", error);
    }
    return error;
  }
};

export const page_access = async (PageID) => {
  const formData = new URLSearchParams();
  formData.append("PageID", PageID);

  try {
    const response = await axios.post(
      `${API_URI2}/role/page_wise_access`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("authToken")}`, // Retrieve token from cookies
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error saving column chooser", error.message);
    throw error;
  }
};
